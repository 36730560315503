import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  message,
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Space,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useParams, useLocation } from "react-router";
import $api from "./service/http";
const { TextArea } = Input;
const { Option } = Select;
function Roomtype() {
  const [form] = Form.useForm();
  const [Data, setData] = useState({});
  const params = useParams();
  const router = useLocation();
  const obj = {
    APT: 132,
    CSU: 130,
    JSU: 138,
    SKS: 126,
    SKT: 126,
    SNG: 128,
    STT: 134,
    STW: 134,
    TRP: 136,
  };
  const onFinish = (values) => {
    console.log("Success:", values);
    $api
      .post(
        "/temp/type/" + params?.id.toUpperCase() + "",
        {
          nameUz: values.nameUz,
          nameRu: values.nameRu,
          nameEn: values.nameEn,
          titleUz: values.titleUz,
          titleRu: values.titleRu,
          titleEn: values.titleEn,
          url: obj[params.id.toUpperCase()],
          price: values.price,
          capacity: values.capacity,
          amenities: values.amenities,
          image: values.image,
          image2: values.image2,
          image3: values.image3,
          images: values.images,
          descriptionUz: values.descriptionUz,
          descriptionEn: values.descriptionEn,
          descriptionRu: values.descriptionRu,
        },
        {
          auth: {
            username: localStorage.getItem("username"),
            password: localStorage.getItem("password"),
          },
        }
      )
      .then((res) => {
        message.success("Saqlandi", res.response);
      })
      .catch((error) => {
        message.error(error.response);
      });
  };

  const onFinishFailed = (errorInfo) => {
    message.info("Iltimos ma'lumotlarni to'ldiring");
  };
  useEffect(() => {
    console.log("shundan keyin data kelishi kerak lekin kelmayapti");
    $api
      .get("/temp/type/" + params?.id.toUpperCase() + "", {
        auth: {
          username: localStorage.getItem("username"),
          password: localStorage.getItem("password"),
        },
      })
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        form?.setFieldsValue(res.data.data);
      })
      .catch((error) => {
        message.error("Xatolik", error.response);
      });
  }, [router, form, params]);
  return (
    <div style={{ paddingTop: "100px" }}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Row>
          <Col xs={{ span: 5, offset: 1 }}>
            <Form.Item
              label="Uz"
              name="nameUz"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder={"Nomini kiriting"}
                defaultValue={Data.nameUz}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Ru"
              name="nameRu"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder={"Nomini kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="En"
              name="nameEn"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder={"Nomini kiriting"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 5, offset: 1 }}>
            <Form.Item
              label="Uz"
              name="titleUz"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Titleni kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Ru"
              name="titleRu"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Titleni kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="En"
              name="titleEn"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Titleni kiriting"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 5, offset: 1 }}>
            <Form.Item
              label="Narxi"
              name="price"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <InputNumber
                placeholder={"Narxini kiriting"}
                style={{ width: "200px" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Necha xonali"
              name="capacity"
              defaultValue={Data.capacity}
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <InputNumber
                placeholder={"Nechi xonaligini kiriting"}
                style={{ width: "200px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.List name="amenities">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "image"]}
                        fieldKey={[fieldKey, "image"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Select
                          placeholder={"Xona turini"}
                          style={{ width: "300px" }}
                        >
                          <Option value={"Coffee"}>Ajoyib nonushta</Option>
                          <Option value={"swimmer"}>Hovuz ko'rinishi</Option>
                          <Option value={"bath"}>Xususiy hammom</Option>
                          <Option value={"Snowflake"}>Havo sovutish</Option>
                          <Option value={"Monitor"}>
                            Yassi ekranli televizor
                          </Option>
                          <Option value={"SpeakerSlash"}>
                            Ovoz o'tkazmaydigan
                          </Option>
                          <Option value={"WifiHigh"}>Bepul wifi</Option>
                          <Option value={"Car"}>Bepul to'xtash joyi</Option>
                        </Select>
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Xususiyat qo'shish
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 5, offset: 1 }}>
            <Form.Item
              label="Image"
              name="image"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder={"Nomini kiriting"}
                defaultValue={Data.nameUz}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Image2"
              name="image2"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder={"Nomini kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="Image3"
              name="image3"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input placeholder={"Nomini kiriting"} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.List name="images">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "image"]}
                        fieldKey={[fieldKey, "image"]}
                        rules={[
                          { required: true, message: "Missing first name" },
                        ]}
                      >
                        <Input placeholder={"Rasm manzili"} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Rasm qo'shish
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 5, offset: 1 }}>
            <Form.Item
              label="descriptionUz"
              name="descriptionUz"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Description kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 11, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="descriptionRu"
              name="descriptionRu"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Description kiriting"} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 5, offset: 1 }} lg={{ span: 6, offset: 2 }}>
            <Form.Item
              label="descriptionEn"
              name="descriptionEn"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <TextArea placeholder={"Description kiriting"} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit" style={{ width: "300px" }}>
            Saqlash
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

export default Roomtype;
